 
<template>
  <section v-if="geoMap" role="contentinfo">
    <Transition name="fade">
      <v-row
        dense
        aria-hidden="false"
        aria-label="GeoMap"
        aria-labelledby="GeoMap"
      >
        <v-col id="GeoMap">
          <div v-geo-map="{ iframe: geoMap }"></div>
        </v-col>
      </v-row>
    </Transition>
  </section>
</template>

<script>
/* eslint-disable no-unused-vars */
import DOMPurify from "dompurify";
export default {
  directives: {
    "geo-map": {
      bind: (el, { value }) => {
        const { iframe } = value;
        if (!iframe) return;
        if (el instanceof HTMLDivElement) {
          el.innerHTML = DOMPurify.sanitize(iframe, {
            ALLOWED_TAGS: ["iframe"],
            FORBID_ATTR: ["width", "height", "id"],
          });
        }
      },
      inserted: (el) => el.focus(),
    },
  },
  computed: {
    geoMap() {
      return this.$store.getters["app/geoMap"];
    },
  },
};
</script>

<style>
#GeoMap iframe {
  width: 100%;
  border-radius: 1rem;
  border: none;
  min-height: 30vh;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;
}
</style>
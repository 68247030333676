<template>
  <v-container class="py-7">
    <div v-if="orderTypeButtonsEnabled" class="mb-6">
      <OrderTypeButtons />
    </div>

    <main-slider v-if="!$store.getters['app/isQrMenu']" />

    <template v-if="$store.getters['aggregator/isAggregator']">
      <MerchantList />
    </template>

    <template v-else>
      <catalog-sections />
    </template>

    <geo-map />
  </v-container>
</template>

<script>
import CatalogSections from '@/components/CatalogSections'
import MainSlider from '@/components/MainSlider'
import GeoMap from '@/components/maps/GeoMap.vue'
import MerchantList from '@/components/MerchantList'
import OrderTypeButtons from '@/components/OrderTypeButtons'

export default {
  name: 'Home',

  components: {
    CatalogSections,
    MainSlider,
    GeoMap,
    MerchantList,
    OrderTypeButtons,
  },

  computed: {
    orderTypeButtonsEnabled() {
      return (
        !this.$store.getters['app/isQrMenu'] &&
        this.$store.getters['app/orderTypeButtonsEnabled']
      )
    },
    appSettings() {
      return this.$store.state.app.settings
    },
    merchantInfo() {
      return this.$store.state.app.info
    },
  },

  mounted() {
    document.querySelector('title').innerText =
      this.appSettings.seo?.seo_title || this.merchantInfo.data.merchant_name
    this.$emitter.emit('mobile-app-launch')
  },
}
</script>

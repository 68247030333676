<template>
  <div>
    <div class="merchant-list-title">
      Все рестораны
    </div>
    <div class="merchant-list">
      <a
        v-for="merchant in merchantList"
        :key="merchant.merchant_code"
        href="#"
        class="merchant"
        @click.prevent="selectMerchant(merchant)"
      >
        <img :src="merchant.merchant_bg" :alt="merchant.merchant_name" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    merchantList() {
      return this.$store.getters["aggregator/merchantList"];
    },
  },

  methods: {
    selectMerchant(merchant) {
      localStorage.setItem("merchantKey", merchant.merchant_key);
      window.location.href = window.location.origin;
    },
  },
};
</script>

<style lang="scss" scoped>
.merchant-list-title {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 8px;
}

.merchant-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 16px 0;
}

.merchant {
  border-radius: 12px;
  overflow: hidden;

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}
</style>
